<template>
  <div class="error-container">
    <div class="error-img">
      <img src="/assets/error/404.png" alt="" />
    </div>
    <div class="error-info">{{ error }}</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      error: this.$route.query.error,
    };
  },
};
</script>
